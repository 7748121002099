import { makeSlug } from "@/helpers/appHelper";
import { wrap, releaseProxy } from "comlink";
import { postAPICall } from "@/helpers/httpHelper";
import { mapGetters } from "vuex";
import QRCode from "qrcode";
import moment from "moment";
import {
  fetchAllEntityCustomizationsById,
  fetchActiveEntityCustomizationsByEntity,
} from "@/repo/entityCustomizationRepo";
import { fetchGlobalVariables } from "@/repo/globalVariables";
import { fetchEntityRelationships } from "@/repo/entityRelationshipsRepo";
import { fetchEntityNotificationTemplates } from "@/repo/notificationTemplatesRepo";
import { fetchEntitiesByPagination, fetchEntityById } from "@/repo/entityRepo";
import { getAllCompanyTemplatesByPagination } from "@/repo/templatesRepo";
import { fetchAllEntityFiltersByEntity } from "@/repo/filtersRepo";
import CustomDashboardHelper from "@/mixins/CustomDashboardHelper.js";
import TemplateBuilderHelper from "@/mixins/templateBuilderHelper.js";
import EntitiesHelper from "@/mixins/EntitiesHelper.js";

export default {
  name: "Data Fetching",
  mixins: [CustomDashboardHelper, TemplateBuilderHelper, EntitiesHelper],
  data() {
    return {
      //data variables
      paramsContent:{},
      selectedIds: "",
      hideActions: "",
      checkRoutekey: "",
      titleBarShow: false,
      data: [],
      formattedEntityData: [],
      showContentView: true,
      entityDataObject: {},
      entityUserInfoData: {},
      allEntitiesData: [],
      companyAllTemplateFields: [],
      allCurrentEntityFields: null,
      currentEntityFields: [],
      allEmailTemplates: [],
      allFileFields: [],
      fieldsObject: {},
      fieldsLabelObject: {},
      groupedData: [],
      getTotalFields: [],
      templateData: [],
      currentEntity: {},
      quickUpdateIndex: -1,
      selectedFields: null,
      customization_id: "",
      selectedLabels: "",
      entity_id: "",
      activeEntityView: "TABLE",
      pdfExport: false,
      isApplicationUserSide: false,
      customization: null,
      originalCurrentEntity: null,
      globalVariablesData: [],
      relationshipsData: [],
      entityFieldsSet: false,
      activeWorkspace: "",
      fieldsListData: [],
      filterColumList: [],
      selectedTableFields: [],
      entityFieldSettings: [],
      selectedEntityFields: [],
      defaultSettingFields: [],
      treeCheckedNodes: null,
      number_fields: [],
      showFieldsParent: {
        profile: "",
        title: "",
        description: "",
        multiselectDisplay: [],
        fields: [],
        chart_field: "",
        chart_type: "PIE",
        board_field: "",
        action_button: "",
        groupBy_chart_field: "",
        action_group_button: [],
        selectedFilter: [],
        selected_email_template: "",
        columnsPerRow: 1,
        emailView: false,
        BoardViewFields: [],
        take_value_from: "",
        Board_action_group_button: [],
        enableOtherOptions: true,
        field1: "",
        field2: "",
        field3: "",
      },
      //Filters
      entityAllFilters: [],
      currentFilter: "",
      entityFiltersData: {
        query_type: "AND",
        filters: [],
        filter_name: "",
        entity_id: "",
        isSaveFilter: false,
        columns_settings: [],
        quick_filters: [],
        displayType: "",
        data_limit: 10,
        email_template_id: "",
        sortBy: "",
        sortOrder: "",
      },
      exportFilters: [],
      //worker variables
      workers: null,
      dataParseWorker: null,
      fieldSettingsWorker: null,
      allFieldKeyLabels: null,
      //page variables
      currentkey: "",
      refreshPage: false,
      limit: 1,
      pageSizes: [5, 10, 20, 50],
      sortBy: "_id",
      sortOrder: -1,
      currentPage: 1,
      pageSize: 0,
      //loading
      childLoading: false,
      loadingText: "",

      /* Filter operations related variables */
      entityUserFiltersData: {
        parentFilterId: "",
        query_type: "AND",
        filters: [],
        filter_name: "",
        isSaveFilter: false,
        data_limit: 10,
        columns_settings: [],
      },
      UsercurrentFilter: "",

      iFrame_url_key: "",
      iFrame_route_type: "",
      iFrameModal: false,
      advancedSearch: false,
      isFilterOpen: false,
      entityViewModal: false, //layout drawer option

      modalLoading: false,

      hasQrcode: false,
      qrCodeField: null,
    };
  },
  computed: {
    ...mapGetters("company", ["getCompanyDetails", "getContactLoginSlug"]),
    ...mapGetters("auth", [
      "getAuthenticatedUser",
      "getDefaultDateFormat",
      "getUserType",
      "getActiveContactType",

      "getUserTypeEmailsErrors",
      "getUserTypeEmails",
      "getUserTypeList",
      "getActiveWorkspace",
      "getGlobalFiltervalue",
    ]),
    ...mapGetters("menuManagementV2", ["getEntityMenu"]),

    ...mapGetters("entities", [
      "getQuickEntityFiltersObject",
      "getPageSize",
      "getEntityPrimaryData",
      "getAllEntitiesInTemplate",
      "getEntityShowFields",
    ]),
    ...mapGetters("entityRelationships", [
      "getParentEntityNestedRelationshipData",
    ]),

    ...mapGetters("iFrame", ["getIframeUrlInfo"]),

    listeners() {
      return {
        WANotification: this.WANotification,
        EmailConfig: this.EmailConfig,
        closeNotificationDialog: this.closeNotificationDialog,
      };
    },

    isRequiredDataPrepared() {
      return true;
    },
    getCurrentFilters() {
      return()=>{
      if (this.$route.query.routeType) {
        if (this.entityFiltersData?.filters?.length) {
          return this.entityFiltersData.filters.filter(
            (filter) => filter.isUserFilter === true
          );
        } else {
          return [];
        }
      }
      return this.entityFiltersData &&
        this.entityFiltersData.filters &&
        this.entityFiltersData.filters.length ?
        this.entityFiltersData.filters :
        [];
      }
    },
    getUserFilter() {
      return ()=>{
      if (this.$route.query.routeType) {
        return (this.entityAllFilters || []).filter(
          (e) =>
            e.role_id === this.getAuthenticatedUser.activeRole.role_id &&
            e.created_by === this.getAuthenticatedUser._id &&
            e.parent_filter_id === this.$route.query.filter
        );
      } else {
        return (this.entityAllFilters || []).filter((e) => !e.parent_filter_id);
      }
    }
    },
    currentMenu() {
      if (
        this.isApplicationUserSide &&
        this.$route?.params?.menuId &&
        this.getEntityMenu?.menu_list
      ) {
        let menu;
        let id = this.$route.params.menuId || this.$route.query.menu_id;
        this.getEntityMenu.menu_list.forEach((e) => {
          if (e._id == id) {
            menu = e;
          } else if (e.children) {
            (e.children || []).forEach((el) => {
              if (el._id == id) {
                menu = el;
              } else if (el.children) {
                (el.children || []).forEach((ele) => {
                  if (ele._id == id) {
                    menu = ele;
                  }
                });
              }
            });
          }
        });
        return menu;
      }
      return null;
    },
    getSelfEntityFilters() {
      if (
        this.isApplicationUserSide &&
        this.getAuthenticatedUser?.contact_types
      ) {
        let currentActiveWorkspace =
          this.getAuthenticatedUser.contact_types.find((e) => {
            let id =
              e.contact_type && e.contact_type._id
                ? e.contact_type._id
                : e.contact_type;
            if (id && id == this.activeWorkspace) {
              return true;
            }
          });
        let value = currentActiveWorkspace?.account_data_id || "",
          relationalEntityId = this.activeWorkspace;
        if (
          this.currentMenu?.self_related_filter != "login_user" &&
          this.getEntityPrimaryData
        ) {
          let [entityId, templateId, fieldKey] =
            this.currentMenu.self_related_filter?.split("#");
          let tempData = this.getEntityPrimaryData.templates_data.find(
            (tempData) => tempData.template_id == templateId
          );
          if (tempData?.template_data_id?.template_data?.[fieldKey]) {
            value = tempData.template_data_id.template_data[fieldKey];
          } else {
            value = "";
          }
          relationalEntityId = entityId;
        }
        return [
          {
            field: "self#related_to/1",
            operator: "=",
            value: value,
            value_type: "",
            value_source: "",
            query_type: "OR",
            value_field: "",
            validations: {
              data_type: "",
            },
            nested_related_id: "",
            data_source: "self_field",
            relation_entity_id: relationalEntityId,
            data_type: "RELATION",
          },
        ];
      }
      return [];
    },
    getCurrentDate() {
      const currentDate = moment().startOf("day");
      const timeZoneOffsetMinutes = new Date().getTimezoneOffset();
      const adjustedDate = currentDate.add(timeZoneOffsetMinutes, "minutes");
      return adjustedDate.format().split("+")[0] + ".000Z";
    },
    getCurrentFilter() {
      return this.entityAllFilters.find(
        (e) => e && e._id == this.currentFilter
      );
    },
    getFilterById(){
      return (id)=>{
        return this.entityAllFilters.find(
          (e) => e && e._id == id
        );
      }
    },
    isValidEntity() {
      return this.currentEntity?.name ? true : false;
    },


    displayBackButton() {
      if (this.getIframeUrlInfo) {
        let modifiedKey = this.checkRoutekey;
        if (this.checkRoutekey.startsWith("if#")) {
          const parts = this.checkRoutekey.split("#");
          if (parts.length > 2) {
            modifiedKey = parts.slice(2).join("#");
          }
        }
        if (modifiedKey === this.getIframeUrlInfo.url_key) {
          return false;
        }
      }
      return true;
    },
    backToEntities() {
      let query = this.getNavigationQuery(this.$route.query);
      if (query.viewType || query.filter) {
        if (query.viewType) {
          delete query.viewType;
        }
        if (query.filter) {
          delete query.filter;
        }
      }
      if (this.getIframeUrlInfo?.url_type) {
        this.$router.push({
          path: `/if/${this.getIframeUrlInfo._id}/entity`,
          query: query,
        });
      } else if (query.fromApprovalForms == "true") {
        if (this.isApplicationUserSide) {
          this.$router.push({
            path: "/ap/approvalforms",
          });
        } else {
          this.$router.push({
            path: "/forms/approval-forms",
          });
        }
      } else {
        this.$router.push({ path: "/entity", query: query });
      }
    },
    getEntityName() {
      return this.currentEntity?.name;
    },
    prepareParams() {
      return {
        name: this.currentEntity.name,
        description: this.currentEntity.description,
        entity_type: this.currentEntity.entity_type,
        templates: this.currentEntity.templates,
        id: this.currentEntity._id,
        primaryFields: this.currentEntity.primaryFields,
        settings: this.selectedFieldKeys,
      };
    },
    openCompanyFilterModel() {
      if (this.UsercurrentFilter) {
        let selectedFilter = this.entityAllFilters.find(
          (e) => e && e._id == this.UsercurrentFilter
        );
        let query_type =
          this.entityFiltersData && this.entityFiltersData.query_type ?
            this.entityFiltersData.query_type :
            selectedFilter &&
              selectedFilter.filters &&
              selectedFilter.filters.length &&
              selectedFilter.filters[0].query_type ?
              selectedFilter.filters[0].query_type :
              "AND";
        this.entityUserFiltersData = {
          ...JSON.parse(JSON.stringify(selectedFilter)),
          ...{
            updateFilter: true,
            query_type: query_type,
          },
        };
        this.openPopup("EntityFilter", {
          currentEntity: this.currentEntity,
          entityFiltersData: this.entityFiltersData,
          entityUserFiltersData: this.entityUserFiltersData,
          selectedEntityFields: this.selectedEntityFields,
          emailTemplates: this.allEmailTemplates,
          Customization: this.customization,
          isFilterDisabled: this.isFilterDisabled,
          getFilterButtonLabel: this.getFilterButtonLabel,
          deleteFilter: this.confimDelete,
          resetFiltersData: this.resetFiltersData,
          applyFilter: this.applyFilter,
          type: "EntityFilter"
        });
      }
      this.dialogEdit = true;
    },
    getfilterLabel() {
      return (filter) => {
        let name = "";
        if (filter && filter.field && this.selectedEntityFields) {
          let filteredTemplate = this.selectedEntityFields.find(
            (e) => e.key == filter.field
          );
          if (filteredTemplate) {
            name =
              (filteredTemplate.template_name ?
                filteredTemplate.template_name :
                "") +
              "-" +
              (filteredTemplate.label ? filteredTemplate.label : "");
          }
        }
        return name;
      }
    },
    getFilterButtonLabel() {
      let name = "Apply";
      const filterData = this.$route.query.routeType
        ? this.entityUserFiltersData
        : this.entityFiltersData;
      if (filterData) {
        if (filterData.isSaveFilter) {
          name = "Save & Apply";
        } else if (filterData.updateFilter) {
          name = "Update & Apply";
        }
      }

      return name;
    },
    getType() {
      if (this.activeEntityView == "CARDS") {
        return "EntityGalleryView";
      } else {
        return "ViewEntityData";
      }
    },
  },
  created() {
  },
  methods: {
    async fetchEntityInfoById(entity_id) {
      this.currentEntity = await fetchEntityById(entity_id)
    },
    terminateWorkers() {
      this.dataParseWorker[releaseProxy]();
      this.fieldSettingsWorker[releaseProxy]();
      this.workers.dataParseWorker.port.close();
      this.workers.fieldSettingsWorker.port.close();
    },
    getRequiredKeyValues() {
      const { key, page, pageSize, order_by, order_type, customization } =
        this.$route.query;
      this.refreshPage = true;
      this.currentkey = key;
      this.currentPage = parseInt(page) || 1;
      this.pageSize = parseInt(pageSize) || 10;

      if (order_by) {
        this.sortBy = order_by;
      }
      if (order_type) {
        this.sortOrder = order_type;
      }

      if (customization) {
        this.customization_id = customization;
      }
      setTimeout(() => {
        this.refreshPage = false;
      });
      this.entity_id = this.$route.params.entity_id;
    },
    async getRequiredData() {
      this.loading = true;
      this.titleBarShow = false;
      let [currentEntity, globalVariables] = await Promise.all([
        fetchEntityById(this.entity_id),
        fetchGlobalVariables({
          get_all: true,
        }),
      ]);
      this.currentEntity = currentEntity;
      this.quickUpdateIndex = -1;
      this.setRouteConfiguration();
      let filters = [];
      if (this.$route.query.filter) {
        const filterObj =
          this.getQuickEntityFiltersObject?.[this.$route.query.filter];
        if (filterObj?.filters && filterObj?.selectedFields) {
          this.selectedFields = filterObj.selectedFields;
          filters = filterObj.filters;
        } else {
          this.$store.commit("entities/setQuickEntityFiltersObject", null, {
            root: true,
          });
        }
      }

      const customizationPromise = this.customization_id
        ? fetchAllEntityCustomizationsById(this.customization_id)
        : fetchActiveEntityCustomizationsByEntity(this.entity_id);

      const additionalPromises = [
        fetchEntityRelationships({ entityId: this.entity_id }),
        this.fetchAllEntityFilters(),
        this.fetchEntityFields(),
        this.fetchEntitiesDataForTable(filters),
        this.fetchEmailTemplates(),
        this.isApplicationUserSide && this.getActiveContactType?.account_data_id
          ? this.$store.dispatch("entities/fetchEntityPrimaryDataById", {
            id: this.getActiveContactType.account_data_id,
          })
          : Promise.resolve(),
      ];

      const [customization, relationships] = await Promise.all([
        customizationPromise,
        ...additionalPromises,
      ]);
      if (customization) {
        this.customization = { ...customization };
      }
      this.originalCurrentEntity = JSON.parse(
        JSON.stringify(this.currentEntity)
      );
      if (globalVariables?.data?.length) {
        this.globalVariablesData = [...globalVariables.data];
      }

      this.relationshipsData = (relationships || []).map((e) => ({
        ...e,
        owner_type:
          e.parent_entity_id === this.currentEntity._id ? "PARENT" : "CHILD",
        relationship_title:
          e.parent_entity_id === this.currentEntity._id
            ? e.child_relation_title
            : e.parent_relation_title,
        relationship_slug: makeSlug(e.relationship_title),
        relationship_id:
          e.parent_entity_id === this.currentEntity._id
            ? e.child_entity_id
            : e.parent_entity_id,
      }));
      if (
        this.currentEntity ||
        (this.$route.params.entity_id && this.$route.params.menuId)
      ) {
        this.pdfExport = !!this.currentEntity?.export_printing_document;
        let data = await this.fieldSettingsWorker.setupEntityFields(
          this.currentEntity,
          this.customization,
          this.getCurrentFilter
        );
        this.entityFieldsSet = true;
        this.fieldsListData = data.fieldsListData;
        this.filterColumList = data.filterColumList;
        this.selectedTableFields = data.selectedTableFields;
        this.defaultSettingFields = data.defaultSettingFields;
        this.treeCheckedNodes = data.filterColumList;
        this.number_fields = data.number_fields;
        this.entityFieldSettings = JSON.parse(
          JSON.stringify(data.selectedTableFields)
        );
        if (
          this.isApplicationUserSide &&
          this.getEntityShowFields?.[this.entity_id]
        ) {
          this.showFieldsParent = {
            ...this.getEntityShowFields[this.entity_id],
          };
        } else if (this.currentEntity?.views_configuration) {
          this.showFieldsParent = { ...this.currentEntity.views_configuration };
          this.showFieldsParent.selected_email_template =
            this.getSelectedEmailTemplate();
          if (!this.showFieldsParent?.columnsPerRow) {
            this.showFieldsParent.columnsPerRow = 2;
          }
        } else {
          this.generateShowFields(
            JSON.parse(JSON.stringify(this.currentEntity))
          );
        }
        this.normalizeFieldKeys();
        if (
          typeof this.showFieldsParent.fields == "string" ||
          this.showFieldsParent.fields == "" ||
          !this.showFieldsParent.fields
        ) {
          this.showFieldsParent.fields = [];
        }
        this.currentEntity.templates.forEach((e) => {
          if (e && e.templateInfo) {
            this.current_entity_tempates.push(
              JSON.parse(JSON.stringify(e.templateInfo))
            );
          }
        });

        this.selectedEntityFields = [];
        this.getTotalFields = [];
        if (this.currentEntity?.templates) {
          this.currentEntity.templates.forEach(async (e) => {
            if (e?.templateInfo && !e.is_repeatable) {
              this.getTotalFields.push(
                ...this.getTemplateFieldsByType(e.templateInfo, [
                  "NUMBER",
                  "CURRENCY",
                  "AGGREGATE_FUNCTION",
                ])
              );

              const customizedFields = this.applyCustomizationOnFields(
                await this.fieldSettingsWorker.getTemplateFields(
                  JSON.parse(JSON.stringify(e.templateInfo)),
                  true,
                  true
                ),
                e.customization,
                e
              ).map((f) =>
                f?.key && !f.key.includes("#")
                  ? { ...f, key: `${f.template_id}#${f.key}` }
                  : f
              );

              this.selectedEntityFields.push(...customizedFields);
            }
          });
        }
        this.setRelationshipFields();

        await this.fetchOtherTemplates(this.selectedEntityFields);

        this.globalVariablesData = this.getAllGlobalVariables?.data || [];
        this.selectedEntityFields = this.setGlobalVariables(
          this.selectedEntityFields,
          this.globalVariablesData,
          this.getTemplateDataTempVariable
        );
        this.allEntityFields = await this.fetchEntityDetails(
          this.currentEntity._id,
          false,
          false,
          false,
          true,
          true,
          false,
          JSON.parse(JSON.stringify(this.currentEntity))
        );
        if (this.currentEntity?.qr_code_import_field) {
          this.qrCodeField = this.allEntityFields.find(
            (f) => f.template_key == this.currentEntity.qr_code_import_field
          );
          this.hasQrcode = true;
        }
        this.hasAttachment = this.allEntityFields.some(
          (e) => e?.input_type === "FILE"
        );
      } else {
        this.loading = false;
        this.forbiddenAccess = true;
        this.showErrorMessage =
          this.getEntityDataByIdError || "Forbidden access";
      }
      this.prepareFormDataForTable();
      this.menuTitleLoading = false;

      this.selectedLabels = this.allCurrentEntityFields.map(
        (field) => field.key
      );
      this.selectAllFields();
      this.loading = false;
      this.titleBarShow = true;
    },
    async prepareFormDataForTable() {
      console.log("prepare", this.data);
      this.formattedEntityData = [];
      this.formattedEntityData = await this.dataParseWorker.formatDataForTable({
        dataArray: this.data,
        columnList: this.filterColumList,
        fieldsObject: this.currentEntityFieldsObject,
      });
      console.log("formattedEntityData", this.formattedEntityData);
    },
    async fetchAllEntityFilters(refreshTable) {

      this.entityAllFilters = [];
      this.entityAllFilters = await fetchAllEntityFiltersByEntity({
        entityId: this.entity_id,
      });
      if (this.entityAllFilters) {
        if (
          this.currentFilter &&
          !this.entityAllFilters.find((e) => e && e._id == this.currentFilter)
        ) {
          this.currentFilter = "";
        }
        if (this.isApplicationUserSide) {
          this.entityAllFilters = this.entityAllFilters.filter(
            (e) => e._id == this.$route.query.filter
          );
        }
        if (this.$route.query.filter) {
          let entityFiltersData = this.entityAllFilters.find(
            (e) => e._id == this.$route.query.filter
          );
          if (entityFiltersData) {
            this.entityFiltersData = { ...entityFiltersData };
            this.currentFilter = this.$route.query.filter;
          } else {
            //removing not existed filter from url
            delete this.$route.query.filter;
            return;
          }
        } else if (refreshTable && this.currentFilter) {
          this.entityFiltersData = this.entityAllFilters.find(
            (e) => e && e._id == this.currentFilter
          );
          await this.fetchEntitiesDataForTable();
        }
        await this.getRelationQuickFilters();
      }
    },
    async getEntitiesAndTemplates() {
      let [entities, templates] = await Promise.all([
        fetchEntitiesByPagination({ get_all: true }, true),
        getAllCompanyTemplatesByPagination(
          {
            get_all: true,
            include_standard: true,
          },
          true
        ),
      ]);
      if (entities?.data) {
        this.allEntitiesData = [...entities.data];
      }
      if (templates?.data) {
        this.companyAllTemplateFields = this.getAllTemplateFieldsObject(
          templates.data
        );
      }
    },
    async initializeWorkersAndSockets() {
      this.workers = {
        dataParseWorker: new SharedWorker("/workers/dataParse.worker.js"),
        fieldSettingsWorker: new SharedWorker(
          "/workers/fieldSettings.worker.js"
        ),
      };
      this.dataParseWorker = wrap(this.workers.dataParseWorker.port);
      this.fieldSettingsWorker = wrap(this.workers.fieldSettingsWorker.port);
      await Promise.all([
        this.dataParseWorker?.setStoreData({
          defaultDateFormat: this.getDefaultDateFormat || "DD-MM-YYYY",
          timeFormat: this.getDefaultTimeFormat === 12 ? "hh:mm A" : "HH:mm:ss",
        }),
      ]);
      this.$socket1.emit(
        "join-workspace",
        this.getCompanyDetails._id || this.getContactLoginSlug._id
      );
      this.$socket1.on("entity-data-added", (data) => {
        this.checkAndUpdateTableData(data);
      });
      this.$socket2.on("entity-data-updated", () => {
        if (this.currentPage == 1) {
          this.fetchEntitiesDataForTable(this.applied_quick_filters, true);
        }
      });
      this.$socket2.on("connectionValidation", async (data) => {
        await postAPICall(
          "PUT",
          "/wa/update-WA-single-integration-data-by-instance",
          { instanceId: data.instanceId }
        );
        this.$notify({
          title: "Warning",
          message: "Connection is not active. Please change the connection.",
          type: "warning",
        });
      });
    },
    setRelationshipFields() {
      if (this.relationshipsData?.length) {
        let count = 0;
        let existedFilters = this.selectedEntityFields
          .filter((e) => e.entity_id)
          .flatMap((e) => e.entity_id);
        this.relationshipsData.forEach((rl) => {
          let isChild = rl.child_entity_id === this.entity_id ? false : true;
          let rlEntityId = isChild ? rl.child_entity_id : rl.parent_entity_id;
          if (existedFilters.indexOf(rlEntityId) === -1) {
            this.selectedEntityFields.push({
              input_type: "RELATION",
              inputType: "RELATION",
              entity_id: rlEntityId,
              key: "self#related_to/" + (count + 1),
              label:
                `With #` +
                (isChild ? rl.child_relation_title : rl.parent_relation_title),
              section_name: "Relation",
              template_name: "Relation",
              data_type: "RELATION",
              validations: {
                data_type: "RELATION",
              },
            });
            count++;
          }
        });
      }
    },
    normalizeFieldKeys() {
      const fieldsToSanitize = ["field1", "field2", "field3"];
      fieldsToSanitize.forEach((field) => {
        if (this.showFieldsParent[field]) {
          this.showFieldsParent[field] = this.normalizeFieldKey(
            this.showFieldsParent[field]
          );
        }
      });
    },
    normalizeFieldKey(value) {
      if (value && value.includes("#")) {
        const parts = value.split("#");
        return [...new Set(parts)].join("#");
      }
      return value;
    },
    async setRouteConfiguration() {
      if (this.$route?.query?.viewType) {
        this.activeEntityView = this.$route?.query?.viewType;
      }
      if (this.$route?.name == "application-user-entity") {
        if (this.getActiveContactType?.contact_type?._id) {
          this.activeWorkspace = this.getActiveContactType.contact_type._id;
        }
        if (this.$route?.params?.menuId) {
          this.isApplicationUserSide = true;
        }
      }
    },
    async fetchEntitiesDataForTable(
      filters = [],
      pageChanged = false,
      reloading = false,
      type = ""
    ) {
      if (type == "child") {
        this.childLoading = true;
        this.loadingText = "Fetching data...";
      } else if (!reloading) {
        this.loadingText = "Fetching data...";
        this.loading = true;
      }
      let nestedRelationalEntityDatas = [];
      if (
        this.currentMenu?.entity_type == "NESTED_RELATIONAL_ENTITY" &&
        this.isApplicationUserSide
      ) {
        nestedRelationalEntityDatas = await this.getNestedRelationshipDataIds();
      }
      if (!this.entityFiltersData && this.$route.query.filter) {
        this.resetEntityFiltersData();
      }
      filters = [...this.entityFiltersData.filters, ...filters];
      // this.exportFilters = filters;
      let checkDataTable = this.filterColumList.find(
        (e) => e.type == "DATA_TABLE"
      );
      if (this.entityFiltersData?.data_limit != 10 && !pageChanged) {
        this.pageSize = Number(this.entityFiltersData.data_limit);
      }
      let sortBy = this.sortBy;

      let sortOrder = this.sortOrder;

      if (this.entityFiltersData?.sortBy && this.entityFiltersData.sortOrder) {
        sortBy = this.entityFiltersData.sortBy;
        sortOrder = this.entityFiltersData.sortOrder;
      }
      if (
        this.currentMenu?.entity_type == "RELATIONAL_ENTITY" &&
        this.isApplicationUserSide
      ) {
        filters = [...filters, ...this.getSelfEntityFilters];
      }

      let params = {
        entity_id: this.entity_id,
        template_fields_data: (this.currentEntity &&
          this.currentEntity.templates
          ? this.currentEntity.templates
          : []
        ).map((template) => {
          return {
            template_id: template.template_id,
          };
        }),
        filters: this.mapDateFilters(
          this.addGlobalFilters(
            filters || [],
            this.entity_id,
            this.checkGlobalFilter
          )
        ),
        limit: this.activeEntityView == "CARDS" ? 12 : this.pageSize,
        page: parseInt(this.getCurrentPage)
          ? parseInt(this.getCurrentPage)
          : this.currentPage,
        sortBy: sortBy,
        sortOrder: sortOrder,
        search_string: this.search_string,
        data_table_field: checkDataTable,
      };
      if (this.$route?.query?.viewType == "HTML_CONTENT") {
        params.viewType = "HTML_CONTENT";
        params["email_template_id"] = this.getSelectedEmailTemplate();
      }
      if (
        this.currentMenu?.entity_type == "NESTED_RELATIONAL_ENTITY" &&
        this.isApplicationUserSide
      ) {
        params.entity_data_ids = nestedRelationalEntityDatas;
        this.exportDataIds = nestedRelationalEntityDatas;
        if (!nestedRelationalEntityDatas.length) {
          this.data = [];
          this.total = 0;
          this.totalData = [];
          this.tableLoading = false;
          return;
        }
      }
      this.applied_filters = [];
      this.applied_filters = this.mapDateFilters(filters || []);
      if (this.entityFiltersData?.tableFieldGroupBy) {
        let subField = this.getField(this.entityFiltersData.tableFieldGroupBy);
        if (subField?.inputType == "MULTI_SELECT") {
          params.unwind = true;
        }
        params.group_by = this.entityFiltersData.tableFieldGroupBy;
        params.group_by_fields = this.entityFiltersData.tableFieldGroupByFields;
        if (this.entityFiltersData?.tableFieldSubGroupBy) {
          params.sub_group_by = this.entityFiltersData?.tableFieldSubGroupBy;
        } else {
          params.entity_variable_columns =
            this.entityFiltersData.filterEntityVariableColumns;
          if (
            this.getField(this.entityFiltersData.tableFieldGroupBy)?.entity_id
          ) {
            params.filter_variable_entity = this.getField(
              this.entityFiltersData.tableFieldGroupBy
            ).entity_id;
          }
        }
      }
      // await this.$store.dispatch("entities/fetchEntityRecordsForTable", params);
      let response = await postAPICall(
        "POST",
        "/entities-data/entity/data",
        params
      );
      this.exportFilters = params.filters;
      if (!this.currentEntity || this.currentEntity?._id !== this.entity_id) {
        this.currentEntity = response.selectedEntity;
        this.prepareKeyLabelsObject();
        await this.$store.commit(
          "entities/setEntityDataById",
          this.currentEntity,
          {
            root: true,
          }
        );
      }
      if (!reloading) {
        this.data = [];
      }
      if (this.entityFiltersData?.columns_settings?.length) {
        this.filterColumList = [];
        this.filterColumList = this.entityFiltersData.columns_settings;
        //map with latest field type
        this.filterColumList =
          await this.fieldSettingsWorker.mapTableFieldsType(
            this.currentEntity,
            this.filterColumList
          );
      }
      if (response) {
        this.hasMore = response.hasMore;
        if (reloading) {
          return {
            data: this.prepareEntityDataToShowInTable(
              JSON.parse(JSON.stringify(response.data)),
              response.selectedEntity
            ),
            total: response.total ? response.total : 0,
          };
        } else {
          this.data = this.prepareEntityDataToShowInTable(
            JSON.parse(JSON.stringify(response.data)),
            response.selectedEntity
          );
        }
        this.entityDataObject = {};
        (this.data || []).map((e) => {
          this.entityDataObject[e._id] = e;
        });
        //this.entityUserInfoData = await this.mapEntityDataWithUserInfo(
        //  this.data
        //);
        this.templateData = response.selectedEntity.templates.map((e) => e);
        this.mapEntityDataWithUserInfo(this.data).then((e) => {
          this.entityUserInfoData = { ...e };
        });
        this.data = this.mapContactProfile(this.data);
        this.totalData = [...this.data];
        this.total = response.total ? response.total : 0;
        if (this.entityFieldsSet) {
          this.prepareFormDataForTable();
        }
        this.custom_columns = [];
        if (this.entityFiltersData?.tableFieldGroupBy) {
          if (this.entityFiltersData?.tableFieldSubGroupBy) {
            let custom_columns = [];
            this.groupedData = response.data.map((data) => {
              if (data?.properties) {
                let label = "",
                  total = 0;
                (data.properties || []).forEach((el) => {
                  if (!label) {
                    label = el.entity_label;
                  }
                  total = total + el.count;
                  let value = el.sub_entity_label
                    ? el.sub_entity_label
                    : el.label;
                  let index = custom_columns.findIndex(
                    (e) => e.value == el.label
                  );
                  if (index == -1 && value) {
                    custom_columns.push({
                      value: el.label,
                      label: value,
                    });
                  }
                  (Object.keys(el) || []).forEach((key) => {
                    if (
                      ["entity_label", "label", "sub_entity_label"].indexOf(
                        key
                      ) == -1
                    ) {
                      data[el.label + "?" + key] = el[key];
                      data[value + "?" + key] = el[key];
                    }
                  });
                });

                data.entity_label = label;
                data.count = total;
              }
              return data;
            });
            if (
              this.entityFiltersData?.table_field_group_by_selected_options &&
              this.entityFiltersData.table_field_group_by_selected_options
                .length
            ) {
              custom_columns = custom_columns.filter(
                (e) =>
                  this.entityFiltersData.table_field_group_by_selected_options.indexOf(
                    e.value
                  ) != -1
              );
            }
            this.entityFiltersData.tableFieldGroupByFields
              .filter((e) => e.sub_group)
              .forEach((field) => {
                (custom_columns || []).forEach((col) => {
                  this.custom_columns.push({
                    label: col.label + "-" + field.name,
                    value: col.value + "?" + field.key,
                  });
                });
              });
          } else {
            this.groupedData = response.data;
            this.groupedData = this.groupedData.map((e) => {
              if (e && e.name && e.name.includes("#")) {
                e.name = e.name.split("#")[0];
              }
              return e;
            });
          }
        }
        this.showContentView = false;
        if (type == "child") {
          this.childLoading = false;
        }
        this.loading = false;
        this.showContentView = true;
      } else {
        if (type == "child") {
          this.childLoading = false;
        }
        this.loading = false;
      }
    },
    prepareEntityDataToShowInTable(data, currentEntity) {
      let allColumns = [];
      (currentEntity.templates || []).forEach((template) => {
        if (
          !template?.is_repeatable &&
          template?.templateInfo?.sections?.[0]?.fields
        ) {
          (
            this.applyCustomizationOnFields(
              template?.templateInfo?.sections[0]?.fields,
              template?.customization,
              template
            ) || []
          ).forEach((field) => {
            let key = field.key;
            allColumns.push({
              ...field,
              ...{
                template_id: template.template_id,
                id: key,
                key: template.template_id + "#" + field.key,
                checkCurrency: this.checkCurrency(field),
                checkIsEntityCount: this.checkIsEntityCount(field),
                getCurrencyFormet: this.getCurrencyFormet(field),
              },
            });
          });
        }
      });
      return data.map((row) => {
        allColumns.forEach((column) => {
          row[column.key] = this.getFieldValue(row, column, column);
          if (column?.checkCurrency) {
            row[column.key + "/checkCurrency"] = column.checkCurrency;
          }
          if (column?.checkIsEntityCount) {
            row[column.key + "/checkIsEntityCount"] = column.checkIsEntityCount;
          }
          if (column?.getCurrencyFormet) {
            row[column.key + "/getCurrencyFormet"] = column.getCurrencyFormet;
          }
        });
        return row;
      });
    },
    prepareKeyLabelsObject() {
      if (!this.allFieldKeyLabels) {
        this.allFieldKeyLabels = {};
        (this.currentEntity?.templates || []).forEach((temp) => {
          let tempFields = {};
          (temp.templateInfo?.sections[0]?.fields || []).forEach((fd) => {
            let fieldKey = fd.key;
            let fieldLabel = fd.label;
            if (fieldKey && fieldKey.includes("#")) {
              let [, cleanedKey] = fieldKey.split("#");
              tempFields[cleanedKey] = fieldLabel;
            } else {
              tempFields[fieldKey] = fieldLabel;
            }
          });

          this.allFieldKeyLabels[temp.template_id] = tempFields;
        });
      }
      return this.allFieldKeyLabels;
    },
    getFieldValue(row, step, field) {
      if (!row.entityData?.[step.template_id]?.[step.id]) {
        if (
          field &&
          field.properties &&
          field.properties.tableDefaultValue &&
          field.properties.tableDefaultValue !== ""
        ) {
          return field?.properties?.tableDefaultValue;
        }
      }
      if (step.input_type == "INTEGRATION_VARIABLE") {
        let integrationVarType =
          step.integration_settings.selectedIntegrationFieldVariableType;
        if (integrationVarType === "date") {
          const dateValue = row.entityData?.[step.template_id]?.[step.id];
          if (dateValue) {
            return moment(dateValue).format("MM-DD-YYYY");
          }
        }
      }
      let checkIsGlobalvariable = this.selectedEntityFields.find(
        (e) => e.key && e.key.split("#")[1] && e.key.split("#")[1] == step.id
      );
      if (
        checkIsGlobalvariable &&
        checkIsGlobalvariable.is_global_variable &&
        checkIsGlobalvariable.global_variable_id
      ) {
        let globalVariable = (this.globalVariablesData || []).find(
          (e) => e._id == checkIsGlobalvariable.global_variable_id
        );
        if (globalVariable) {
          if (globalVariable.input_type == "LIST" && globalVariable.list_data) {
            const listValue = globalVariable.list_data.find(
              (data) =>
                data.value == row.entityData[step.template_id]?.[step.id]
            );
            if (listValue) {
              return listValue.name;
            }
          } else if (
            globalVariable.input_type == "IMAGE" &&
            globalVariable.image_url
          ) {
            return globalVariable.image_url;
          } else if (globalVariable.value) return globalVariable.value;
        }
      }
      if (step.type == "CHECKBOX") {
        if (
          row.entityData &&
          step.template_id &&
          row.entityData[step.template_id]
        ) {
          switch (row?.entityData?.[step.template_id]?.[step.id]) {
            case true:
              if (
                field &&
                field.properties &&
                field.properties.checkedLabel &&
                field.properties.checkedLabel !== ""
              ) {
                return field.properties.checkedLabel;
              }
              return true;
            case false:
              if (
                field &&
                field.properties &&
                field.properties.unCheckedLabel &&
                field.properties.unCheckedLabel !== ""
              ) {
                return field.properties.unCheckedLabel;
              }
              return false;
          }
        }
      } else if (step.type == "WEEKDAYS") {
        if (
          row.entityData &&
          step.template_id &&
          row.entityData[step.template_id] &&
          row.entityData[step.template_id][step.id]
        ) {
          if (
            checkIsGlobalvariable?.allow_multiple &&
            typeof row.entityData[step.template_id][step.id] == "object" &&
            row.entityData[step.template_id][step.id].length
          ) {
            let mappedArray = row.entityData[step.template_id][step.id].map(
              (e) => this.weekDayMap[e]
            );
            return mappedArray.join(", ");
          } else {
            return this.weekDayMap[row.entityData[step.template_id][step.id]];
          }
        }
        return "";
      } else if (step.type == "CAPTCHA") {
        return row.entityData[step.template_id][step.id]
          ? "Verified"
          : "Not verified";
      } else if (step.type == "QR") {
        let qr = null;
        // qrType
        if (step.currency_code.qrType === "From Field") {
          if (
            row.entityData &&
            row.entityData[step.template_id] &&
            step.currency_code.qr_data_field
          ) {
            let obj = row.entityData[step.template_id]
              ? row.entityData[step.template_id]
              : {};
            if (step.currency_code.qr_data_field in obj) {
              let value =
                "" +
                row.entityData[step.template_id][
                step.currency_code.qr_data_field
                ];
              QRCode.toDataURL(
                value,
                { errorCorrectionLevel: "H" },
                (err, url) => {
                  if (err) throw err;
                  qr = url;
                }
              );
              return qr;
            } else {
              return qr;
            }
          }
        } else if (step.currency_code.qrType === "Record Id") {
          QRCode.toDataURL(
            row._id,
            { errorCorrectionLevel: "H" },
            (err, url) => {
              if (err) throw err;
              qr = url;
            }
          );
          return qr;
        } else if (step.currency_code.qrType === "URL") {
          // Record Id
          const data = {
            referenceId: this.currentEntity._id,
            dataId: row._id,
          };
          // this.qrOutput(JSON.stringify(data));
          QRCode.toDataURL(
            JSON.stringify(data),
            { errorCorrectionLevel: "H" },
            (err, url) => {
              if (err) throw err;
              qr = url;
            }
          );
          return qr;
        }
      } else if (step.type == "HTML_CONTENT") {
        let newfd = this.currentEntity.templates.find((el) => {
          if (el.template_id === step.template_id) {
            return el.templateInfo;
          }
        });
        let val = newfd.templateInfo.sections[0].fields.find((el) => {
          let keyVal = el.key.split("#")[1];
          if (keyVal === step.id && el.input_type == "HTML_CONTENT") {
            return el;
          }
        });

        return val.content;
        // return row.entityData[step.template_id][step.id]
      } else if (step.type == "IMAGE") {
        let id = this.generateKeyForField(step.label);
        if (
          row.entityData &&
          step.template_id &&
          row.entityData[step.template_id] &&
          row.entityData[step.template_id][id]
        ) {
          return row.entityData[step.template_id][id];
        }
      } else if (
        step.type == "FILE" &&
        row.entityData &&
        step.template_id &&
        row.entityData[step.template_id] &&
        row.entityData[step.template_id][step.id]
      ) {
        this.documentsData =
          row.entityData[step.template_id][step.id].files &&
            row.entityData[step.template_id][step.id].files.length
            ? row.entityData[step.template_id][step.id].files
            : row.entityData[step.template_id][step.id];
        // this.documentSearchData = this.documentsData;
        return row.entityData[step.template_id][step.id].files &&
          row.entityData[step.template_id][step.id].files.length
          ? row.entityData[step.template_id][step.id].files
          : row.entityData[step.template_id][step.id];
      } else if (
        row.entityData &&
        step.template_id &&
        row.entityData[step.template_id] &&
        row.entityData[step.template_id][step.id]
      ) {
        if (
          typeof row.entityData[step.template_id][step.id] == "number" &&
          this.isFloat(row.entityData[step.template_id][step.id])
        ) {
          return parseFloat(row.entityData[step.template_id][step.id]).toFixed(
            2
          );
        }
        if (step.type == "MULTI_SELECT") {
          let value = row.entityData[step.template_id][step.id]
            ? row.entityData[step.template_id][step.id]
            : "";
          if (value) {
            if (typeof value == "object" && value.length) {
              return value.join(",");
            }
            return value;
          }
          return "";
        } else if (
          step.type == "SELECT" &&
          row.entityData[step.template_id][step.id]
        ) {
          let result,
            field = this.selectedEntityFields.find(
              (e) => e.key === step.template_id + "#" + step.id
            );
          if (
            field &&
            field.inputType == "SELECT" &&
            field.is_list &&
            field.global_variable_id
          ) {
            let value = field.options.find(
              (e) => e.value == row.entityData[step.template_id][step.id]
            );
            if (value && value.name) {
              result = value.name;
            }
            result = row.entityData[step.template_id][step.id];
          } else {
            result = row.entityData[step.template_id][step.id];
          }
          if (result && typeof result == "object" && result.length) {
            result = result[0];
          }
          return result;
        } else if (
          step.type == "LIST" &&
          row.entityData[step.template_id][step.id]
        ) {
          let result,
            field = this.selectedEntityFields.find(
              (e) => e.key === step.template_id + "#" + step.id
            );
          if (field?.list_data) {
            let value = field.list_data.find(
              (e) =>
                e.value == row.entityData[step.template_id][step.id] &&
                (e.name ==
                  row.entityData[step.template_id][step.id + "_info"] ||
                  !row.entityData[step.template_id][step.id + "_info"])
            );
            if (value && value.name) {
              result = value.name;
            } else {
              result = row.entityData[step.template_id][step.id + "_info"];
            }
          } else {
            result = row.entityData[step.template_id][step.id + "_info"];
          }
          if (result && typeof result == "object" && result.length) {
            result = result[0];
          }

          return result;
        } else if (
          step.type == "NUMBER" &&
          row.entityData[step.template_id][step.id]
        ) {
          let template = this.currentEntity?.templates.find(
            (e) => e.templateInfo._id.toString() == step.template_id
          );
          let field = template?.templateInfo?.sections[0]?.fields.find(
            (e) => e.key == step.id
          );
          if (field && field.properties.number_type == "PERCENTAGE") {
            return typeof row.entityData[step.template_id][step.id] == "number"
              ? row.entityData[step.template_id][step.id] + "%"
              : !isNaN(row.entityData[step.template_id][step.id])
                ? parseInt(row.entityData[step.template_id][step.id]) + "%"
                : "";
          }
          if (
            field?.input_type === "ENTITY_VARIABLE" &&
            field?.properties.number_type === "PERCENTAGE"
          ) {
            return typeof row.entityData[step.template_id][step.id] === "number"
              ? row.entityData[step.template_id][step.id] + "%"
              : !isNaN(row.entityData[step.template_id][step.id])
                ? parseInt(row.entityData[step.template_id][step.id], 10) + "%"
                : "";
          }
          return typeof row.entityData[step.template_id][step.id] == "number"
            ? row.entityData[step.template_id][step.id]
            : !isNaN(row.entityData[step.template_id][step.id])
              ? parseInt(row.entityData[step.template_id][step.id])
              : "";
        } else if (step.input_type == "CHECKBOX_GROUP") {
          if (
            row.entityData &&
            step.template_id &&
            row.entityData[step.template_id] &&
            row.entityData[step.template_id][step.id] &&
            typeof row.entityData[step.template_id][step.id] == "object" &&
            row.entityData[step.template_id][step.id].length
          ) {
            return row.entityData[step.template_id][step.id].length
              ? row.entityData[step.template_id][step.id].join(",")
              : "";
          } else {
            return row.entityData[step.template_id][step.id].length
              ? row.entityData[step.template_id][step.id]
              : "-";
          }
        } else if (
          step.type == "TIME_RANGE" &&
          row.entityData[step.template_id][step.id].length
        ) {
          if (row.entityData[step.template_id][step.id].length) {
            return (
              this.$moment(row.entityData[step.template_id][step.id][0]).format(
                "HH:mm:ss"
              ) +
              " - " +
              this.$moment(row.entityData[step.template_id][step.id][1]).format(
                "HH:mm:ss"
              )
            );
          }
        } else if (
          step.type == "LOCATION" &&
          row.entityData[step.template_id][step.id].length
        ) {
          this.savedCoordinates = row.entityData[step.template_id][step.id];
          return row.entityData[step.template_id][step.id];
        } else if (
          step.type == "DATE_TIME_RANGE" &&
          row.entityData[step.template_id][step.id].length
        ) {
          if (row.entityData[step.template_id][step.id].length) {
            return (
              this.$moment(row.entityData[step.template_id][step.id][0]).format(
                "MM-DD-YYYY hh:mm:ss A"
              ) +
              " To " +
              this.$moment(row.entityData[step.template_id][step.id][1]).format(
                "MM-DD-YYYY hh:mm:ss A"
              )
            );
          }
        } else if (
          step.type == "DATE_RANGE" &&
          row.entityData[step.template_id][step.id].length
        ) {
          if (row.entityData[step.template_id][step.id].length) {
            return (
              this.$moment(row.entityData[step.template_id][step.id][0]).format(
                "MM-DD-YYYY"
              ) +
              " To " +
              this.$moment(row.entityData[step.template_id][step.id][1]).format(
                "MM-DD-YYYY"
              )
            );
          }
        } else if (
          step.type == "QUESTION" &&
          Array.isArray(row.entityData[step.template_id][step.id])
        ) {
          return row.entityData[step.template_id][step.id].join(",");
        } else if (step.type == "ENTITY" && field?.show_count) {
          return row.entityData[step.template_id][step.id];
        }
        if (row.entityData[step.template_id][step.id + "/name"]) {
          if (
            typeof row.entityData[step.template_id][step.id + "/name"] ==
            "object" &&
            row.entityData[step.template_id][step.id + "/name"].length
          ) {
            return row.entityData[step.template_id][step.id + "/name"].join(
              ","
            );
          } else if (
            this.checkDate(row.entityData[step.template_id][step.id + "/name"])
          ) {
            const format = this.getDefaultDateFormat
              ? this.getDefaultDateFormat
              : "MM-DD-YYYY";
            return moment(
              row.entityData[step.template_id][step.id + "/name"]
            ).format(format);
          }
          return row.entityData[step.template_id][step.id + "/name"];
        }
        if (row.entityData[step.template_id][step.id + "_masked"]) {
          return row.entityData[step.template_id][step.id + "_masked"];
        } else if (step.input_type == "PASSWORD") {
          const passwordLength =
            row.entityData[step.template_id][step.id].length;
          return "*".repeat(passwordLength);
        } else {
          let value = row.entityData[step.template_id][step.id];
          if (
            Array.isArray(value) &&
            !["DATE_RANGE", "TIME_RANGE", "DATE_TIME_RANGE"].includes(
              step.inputType
            )
          ) {
            return value.join(", ");
          } else {
            return value;
          }
        }
      } else if (
        row.entityData &&
        step.template_id &&
        row.entityData[step.template_id] &&
        typeof row.entityData[step.template_id][step.id] == "number"
      ) {
        return row.entityData[step.template_id][step.id];
      }
      return "";
    },
    getCurrencyFormet(field) {
      let codes = {
        USD: {
          currency: "USD",
          code: "en-US",
        },
        INR: {
          currency: "INR",
          code: "en-IN",
        },
        EUR: {
          currency: "EUR",
          code: "en-EU",
        },
        AUD: {
          currency: "AUD",
          code: "en-AU",
        },
        CAD: {
          currency: "CAD",
          code: "en-CA",
        },
        ZAR: {
          currency: "ZAR",
          code: "en-ZA",
        },
        MYR: {
          currency: "MYR",
          code: "ms-MY",
        },
        GBP: {
          currency: "GBP",
          code: "en-GB",
        },
        MXN: {
          currency: "MXN",
          code: "es-MX",
        },
      };
      if (field && field.validations && field.validations.currency) {
        return codes[field.validations.currency];
      } else if (field && field.currency_code && field.currency_code.currency) {
        return codes[field.currency_code.currency];
      } else {
        return {
          currency: "USD",
          code: "en-US",
        };
      }
    },
    checkIsEntityCount(field) {
      return field && field.show_count ? true : false;
    },
    checkCurrency(field) {
      if (
        field?.show_count &&
        (field.show_count_type === "sum" ||
          field.show_count_type === "average") &&
        field.count_field
      ) {
        let [t, k] = field.count_field.split("#");
        let selectedEntity = this.getAllEntitiesInTemplate?.[field.entity_id];
        let selectTemplate = (selectedEntity?.templates || []).find(
          (e) => e.template_id == t
        );
        let isCurrencyField = (
          selectTemplate?.templateInfo?.sections?.[0]?.fields || []
        ).find((e) => e.key == k);
        if (isCurrencyField?.validations?.currency) {
          return isCurrencyField.validations;
        }
        return null;
      }
      return null;
    },
    getSelectedEmailTemplate() {
      let emailTemplateId;
      if (this.showFieldsParent.selected_email_template) {
        emailTemplateId = this.showFieldsParent.selected_email_template;
      } else if (this.$route?.query?.filter) {
        let selectedFilter = this.entityAllFilters.find(
          (e) => e && e._id == this.$route?.query?.filter
        );
        if (selectedFilter?.email_template_id) {
          emailTemplateId = selectedFilter.email_template_id;
        }
      } else if (this.allEmailTemplates?.length) {
        emailTemplateId = this.allEmailTemplates[0]._id;
      }
      return emailTemplateId;
    },
    async fetchEmailTemplates() {
      this.allEmailTemplates = await fetchEntityNotificationTemplates({
        entity_id: this.$route.params.entity_id,
      });
    },
    async getNestedRelationshipDataIds() {
      if (
        this.isApplicationUserSide &&
        this.getAuthenticatedUser?.contact_types &&
        this.currentMenu?.nested_relational_entity
      ) {
        let currentActiveWorkspace =
          this.getAuthenticatedUser.contact_types.find((e) => {
            let id =
              e.contact_type && e.contact_type._id
                ? e.contact_type._id
                : e.contact_type;
            if (id && id == this.activeWorkspace) {
              return true;
            }
          });
        let params = {
          parent_entity_id: this.activeWorkspace,
          parent_entity_data_id: currentActiveWorkspace.account_data_id,
          child_entity_id:
            this.currentMenu.nested_relational_entity.split("#")[0],
          nested_child_entity_id:
            this.currentMenu.nested_relational_entity.split("#")[1],
        };
        await this.$store.dispatch(
          "entityRelationships/fetchParentEntityNestedRelationshipData",
          params
        );
        if (
          this.getParentEntityNestedRelationshipData?.child_entity_data_ids
            ?.length
        ) {
          return this.getParentEntityNestedRelationshipData
            .child_entity_data_ids;
        }
      }
      return [];
    },
    resetEntityFiltersData() {
      const resetData = {
        filter_name: "",
        isSaveFilter: false,
        query_type: "AND",
        quick_filters: [],
        columns_settings: [],
        data_limit: 10,
      };
      if (this.$route.query.routeType) {
        this.entityUserFiltersData = {
          ...resetData,
          filters: [],
        };
        if (this.$route.query.filter) {
          this.entityFiltersData = {
            ...resetData,
            filters: this.entityFiltersData.filters.filter(
              (filter) => !filter.isUserFilter
            ),
          };
        } else {
          this.entityFiltersData = {
            ...{
              filters: [],
              filter_name: "",
              isSaveFilter: false,
              query_type: "AND",
              quick_filters: [],
              columns_settings: [],
              data_limit: 10,
            },
          };
        }
      } else {
        this.entityFiltersData = {
          ...{
            filters: [],
            filter_name: "",
            isSaveFilter: false,
            query_type: "AND",
            quick_filters: [],
            columns_settings: [],
            data_limit: 10,
          },
        };
      }

      this.relationQuickFilters = [];
    },
    mapDateFilters(filters) {
      return filters.map((d) => {
        let e = JSON.parse(JSON.stringify(d));
        if (e.global_variable_id) {
          let variable = (this.globalVariablesData || []).find(
            (fl) => fl._id == e.global_variable_id
          );
          if (variable?.value) {
            if (this.isNumber(variable.value)) {
              e.value = parseInt(variable.value);
            } else {
              e.value = variable.value;
            }
          }
        }
        if (
          e.operator == "real_time" &&
          e.data_type == "DATE" &&
          !e.every_year
        ) {
          let data = this.getDatePickerHelper1(e.selected_date_picker, e.value);
          e.value = data.value;
          e.today = data.today;
          e.operator = "between";
        } else if (
          e.data_source == "login_user" &&
          e.value_field &&
          this.getAuthenticatedUser[e.value_field]
        ) {
          e.value = this.getAuthenticatedUser[e.value_field];
        } else if (
          e.field == "created_by" &&
          e.data_source == "LOGIN_USER" &&
          this.getAuthenticatedUser
        ) {
          e.value = [this.getAuthenticatedUser._id];
        } else if (
          e.field == "updated_by" &&
          e.data_source == "LOGIN_USER" &&
          this.getAuthenticatedUser
        ) {
          e.value = [this.getAuthenticatedUser._id];
        } else if (
          e.data_type == "DATE_TIME" &&
          e.data_source == "REAL_TIME_PICKER" &&
          e.selected_date_picker &&
          e.operator == "dateinclude"
        ) {
          let data = this.getDatePickerHelper(e.selected_date_picker);
          e.value = data.value;
        } else if (e.data_type == "NUMBER" && e.operator == "between") {
          let data = [e.range1, e.range2];
          e.value = data;
        } else if (e.data_type == "CURRENCY" && e.operator == "between") {
          let data = [e.range1, e.range2];
          e.value = data;
        } else if (
          e.data_type == "DATE" &&
          e.value &&
          e.data_source == "CURRENT_DATE"
        ) {
          if (e.value == "today") {
            if (e.operator == ">") {
              e.value = moment().endOf("day").toISOString();
            } else if (e.operator == ">=") {
              e.value = moment().startOf("day").toISOString();
            } else if (e.operator == "<") {
              e.value = moment().startOf("day").toISOString();
            } else if (e.operator == "<=") {
              e.value = moment().endOf("day").toISOString();
            } else if (e.operator == "=") {
              let data = this.getDatePickerHelper1("today", moment());
              e.value = data.value;
              e.today = data.today;
              e.operator = "between";
            }
          }
        } else if (
          e.data_type == "DATE" &&
          e.value &&
          ["before", "after", "weekdays"].indexOf(e.operator) == -1
        ) {
          if (e.operator == "=") {
            let data = this.getDatePickerHelper1("custom_date", e.value);
            e.value = data.value;
            e.today = data.today;
            e.operator = "between";
          } else if (
            ["notbetween", "between"].includes(e.operator) &&
            e.value
          ) {
            let data = this.getDatePickerHelper1("custom_date_range", e.value);
            e.value = data.value;
            e.today = data.today;
          } else if (
            e.operator == "real_time" &&
            e.data_type == "DATE" &&
            e.value &&
            !e.every_year
          ) {
            let data = this.getDatePickerHelper1(
              e.selected_date_picker,
              e.value
            );
            e.value = data.value;
            e.today = data.today;
            e.operator = "between";
          } else {
            if ([">=", ">"].includes(e.operator)) {
              e.value = moment(new Date(e.value)).startOf("day");
            } else {
              e.value = moment(new Date(e.value)).endOf("day");
            }
          }
        }
        e["current_date"] = this.getCurrentDate;
        return e;
      });
    },
    mapContactProfile(entityData) {
      return entityData.map((el) => {
        if (el?.contact_id?.[0]?.avatar) {
          el.avatar = el?.contact_id?.[0]?.avatar;
        }
        return el;
      });
    },
    async fetchEntityFields() {
      try {
        if (this.entity_id) {
          let [allCurrentEntityFields, currentEntityFields] = await Promise.all(
            [
              this.fetchEntityDetails(
                this.entity_id,
                true,
                false,
                true,
                false,
                true,
                false,
                JSON.parse(JSON.stringify(this.currentEntity))
              ),
              this.fetchEntityDetails(
                this.entity_id,
                true,
                false,
                false,
                false,
                true,
                false,
                JSON.parse(JSON.stringify(this.currentEntity))
              ),
            ]
          );
          this.allCurrentEntityFields = allCurrentEntityFields;
          this.currentEntityFields = currentEntityFields;
          this.allCurrentEntityFields = allCurrentEntityFields.filter(
            (field) => field.key && field.input_type !== "FILE"
          );
          this.allFileFields = allCurrentEntityFields.filter(
            (field) => field.key && field.input_type === "FILE"
          );
          this.fieldsObject = {};
          this.allCurrentEntityFields.map((e) => {
            this.fieldsObject[e.template_key] = e;
            this.fieldsLabelObject[e.label.toString()] = e;
          });
          this.currentEntityFields.map((e) => {
            this.currentEntityFieldsObject[e.template_key] = e;
          });
        } else {
          console.warn("No entity_id found for the current entity.");
        }
      } catch (error) {
        console.error("Error fetching entity fields:", error);
        this.loading = false;
      }
    },
    generateShowFields(entity) {
      if (entity?.templates) {
        if (entity?.entity_type == "INDIVIDUAL") {
          let standardTemp = entity?.templates.find(
            (e) => e && e?.templateInfo?.type == "STANDARD"
          );
          this.showFieldsParent.profile = "defaultpic";
          if (standardTemp?.template_id) {
            this.showFieldsParent.title =
              standardTemp.template_id + "#" + "name";
            this.showFieldsParent.description =
              standardTemp.template_id + "#" + "title";
          }
        } else {
          entity?.templates.forEach(async (temp) => {
            if (temp?.templateInfo && !temp?.is_repeatable) {
              if (!this.showFieldsParent.profile) {
                let field = (
                  (await this.fieldSettingsWorker.getTemplateFields(
                    temp.templateInfo
                  )) || []
                ).find((el) => el.inputType == "IMAGE");
                if (field?.key) {
                  this.showFieldsParent.profile = field.key;
                }
              }
              if (!this.showFieldsParent.description) {
                let field = (
                  (await this.fieldSettingsWorker.getTemplateFields(
                    temp.templateInfo
                  )) || []
                ).find((el) => el.inputType == "MULTI_LINE_TEXT");
                if (field?.key) {
                  this.showFieldsParent.description = field.key;
                }
              }
            }
          });
          if (
            !this.showFieldsParent.title &&
            entity.primaryFields &&
            entity.primaryFields[0]
          ) {
            this.showFieldsParent.title =
              entity.primaryFields[0].template_id +
              "#" +
              entity.primaryFields[0].key;
          }
        }
        entity?.templates.forEach(async (temp) => {
          if (!this.showFieldsParent.chart_field) {
            let field = (
              (await this.fieldSettingsWorker.getTemplateFields(
                temp.templateInfo
              )) || []
            ).find(
              (el) =>
                ["SELECT", "YES_OR_NO", "MULTI_SELECT"].indexOf(el.inputType) !=
                -1
            );
            if (field?.key) {
              this.showFieldsParent.chart_field = field.key;
            }
          }
          if (!this.showFieldsParent.board_field) {
            let field = (
              (await this.fieldSettingsWorker.getTemplateFields(
                temp.templateInfo
              )) || []
            ).find(
              (el) =>
                ["SELECT", "YES_OR_NO", "MULTI_SELECT"].indexOf(el.inputType) !=
                -1
            );
            if (field?.key) {
              this.showFieldsParent.board_field = field.key;
            }
          }
        });
      }
    },


    /* Layout pages */

    ViewConfigPage() {
      this.iFrame_url_key = this.$route.fullPath;
      this.iFrame_route_type = this.getType;
      this.iFrameModal = true;
    },
    setTempEntityData(row) {
      row.templates_data = (row?.templates_data || []).map((td) => {
        if (td.template_data_id) {
          td.template_data_id = {
            created_at: row.created_at,
            created_by: row.created_by,
            updated_at: row.updated_at,
            updated_by: row.updated_by,
            template_data: row?.entityData?.[td.template_id] || {},
            template_id: td.template_id,
            status: row.status,
          };
        }
        return td;
      });
      // row.contact_id = this.entityUserInfoData[row.contact_id];
      this.$store.commit("entitiesData/setTempEntityData", row, { root: true });
    },
    openFilterModel() {
      if (this.currentFilter) {
        let selectedFilter = this.entityAllFilters.find(
          (e) => e && e._id == this.currentFilter
        );
        let quick_filters = this.entityFiltersData.quick_filters || [];
        let query_type =
          this.entityFiltersData && this.entityFiltersData.query_type ?
            this.entityFiltersData.query_type :
            selectedFilter &&
              selectedFilter.filters &&
              selectedFilter.filters.length &&
              selectedFilter.filters[0].query_type ?
              selectedFilter.filters[0].query_type :
              "AND";
        this.entityFiltersData = {
          ...JSON.parse(JSON.stringify(selectedFilter)),
          ...{
            updateFilter: true,
            query_type: query_type,
          },
        };
        if (!this.entityFiltersData.quick_filters) {
          this.$set(this.entityFiltersData, "quick_filters", quick_filters);
        }
        if (!this.entityFiltersData.permissions) {
          this.$set(this.entityFiltersData, "permissions", {
            templates: [
              ...this.currentEntity.templates.flatMap((e) => e.template_id),
            ],
            access_set: ["VIEW", "UPDATE", "DELETE", "ADD"],
            filter_permissions: ["CREATE FILTER"],
          });
        }
      }
      this.dialogEdit = true;

      this.openPopup("EntityFilter", {
        currentEntity: this.currentEntity,
        entityFiltersData: this.entityFiltersData,
        entityUserFiltersData: this.entityUserFiltersData,
        selectedEntityFields: this.selectedEntityFields,
        emailTemplates: this.allEmailTemplates,
        Customization: this.customization,
        isFilterDisabled: this.isFilterDisabled,
        getFilterButtonLabel: this.getFilterButtonLabel,
        deleteFilter: this.confimDelete,
        resetFiltersData: this.resetFiltersData,
        applyFilter: this.applyFilter,
        type: "EntityFilter"
      });

    },

    async openSettingsModal() {
      if (
        this.entityFiltersData &&
        this.entityFiltersData.columns_settings &&
        this.entityFiltersData.columns_settings.length
      ) {

        console.log("Settings Action 1");
        this.refresh = true;
        this.selectedTableFields = [];
        let allKeys = this.fieldsListData
          .flatMap((temp) => temp.children)
          .map((fd) => fd.template_id + "#" + fd.id);
        this.entityFiltersData.columns_settings =
          this.entityFiltersData.columns_settings.filter((fd) =>
            allKeys.includes(fd.template_id + "#" + fd.id)
          );
        this.selectedTableFields = this.getValidColumnSettings(
          this.entityFiltersData.columns_settings
        ).map((e) => e.id);
        this.treeCheckedNodes = this.getValidColumnSettings(
          this.entityFiltersData.columns_settings
        );
        setTimeout(() => {
          this.refresh = false;
        });
      }
      if (this.currentFilter && this.getCurrentFilter) {
        console.log("Settings Action 2");
        this.filterColumns = [];
        if (this.getCurrentFilter.tableFieldGroupByFields) {
          this.getCurrentFilter.tableFieldGroupByFields.forEach((e) => {
            if (e?.sub_group) {
              this.filterColumns.push("sub_group/" + e.key);
            } else {
              this.filterColumns.push(e.key);
            }
          });
        } else {
          this.filterColumns.push("count");
        }
        if (this.getCurrentFilter?.tableFieldGroupBy) {
          let subField = this.getField(this.getCurrentFilter.tableFieldGroupBy);
          if (this.getCurrentFilter?.filterEntityVariableColumns) {
            this.filterVariableColumns =
              this.getCurrentFilter.filterEntityVariableColumns;
          }
          if (subField?.input_type == "ENTITY") {
            this.loading = true;
            this.allOtherEntityFields = await this.fetchEntityDetails(
              subField.entity_id,
              true
            );
            this.allOtherEntityFields = this.allOtherEntityFields.filter(
              (e) => e.key
            );
            this.loading = false;
          }
        }
      }
      this.dialogTableVisible = true;



      this.openPopup("Settings",
        {
          updateFilterColumn: this.updateFilterColumn,
          update: this.update,
          currentFilter: this.currentFilter,
          modalLoading: this.modalLoading,
          entityAllFilters: this.entityAllFilters,
          getCurrentFilter: this.getCurrentFilter,
          type: "Settings",
          refresh: this.refresh,
          fieldsListData: this.fieldsListData,
          filterNode: this.filterNode,
          selectedTableFields: this.selectedTableFields,
          checkedNodes: this.checkedNodes,
          treeCheckedNodes: this.treeCheckedNodes
        });
    },

    async zipImportDialog() {
      this.loading = true;
      this.ImportZip = true;
      this.files = [];
      this.zipFiles = [];
      this.loading = false;
    },
    checkDate(string) {
      let dateStamp = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;
      if (dateStamp.test(string)) {
        return true;
      }
      return false;
    },
    getCurrentEntityFieldsByInputTypes(types) {
      return (
        this.currentEntityFields.filter((e) => e.input_type.includes(types)) || []
      );
    },
    isValidURL(url) {
      let re =
        /[-a-zA-Z0-9@:%_+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_+.~#?&//=]*)?/gi;
      if (re.test(url)) {
        return true;
      } else {
        return false;
      }
    },
    getValidUrl(url) {
      if (!url.includes("http://") && !url.includes("https://"))
        return "https://" + url;
      return url;
    },
    isValidHttpUrl(string) {
      let url;
      try {
        url = new URL(string);
      } catch (_) {
        return false;
      }
      return url.protocol === "http:" || url.protocol === "https:";
    },
    checkButtonVisible(buttonName) {
      if (
        this.customization &&
        this.customization.buttons &&
        this.customization.buttons.length
      ) {
        const button = this.customization.buttons.find(
          (b) => b.buttonName === buttonName
        );
        return button ? button.visibility : true;
      }
      return true;
    },
    updateNodesData(event) {
      if (this.selectedTableFields.indexOf(event.id) !== -1) {
        const index = this.selectedTableFields.indexOf(event.id);
        if (index > -1) {
          this.selectedTableFields.splice(index, 1);
        }
        //this.filterColumList = this._.filter(
        //  this.filterColumList,
        //  function (o) {
        //    return o.id != event.id;
        //  }
        //);
        this.filterColumList = this.filterColumList.filter(
          (o) => o.id != event.id
        );
      } else {
        this.filterColumList.push(event);
        this.selectedTableFields.push(event.id);
      }
    },
    checkedNodes() {
      let checked = this.$refs.tree.getCheckedNodes();
      //let checkedNodes = lodash.filter(checked, (node) => {
      //  return !node.children;
      //});
      let checkedNodes = checked.filter((node) => !node.children);
      checkedNodes.map((node) => {
        let existed = this.treeCheckedNodes.filter(
          (nd) => nd.id == node.id && nd.template_id == node.template_id
        );
        if (!existed.length) {
          this.treeCheckedNodes.push(node);
        }
      });
      this.treeCheckedNodes = this.treeCheckedNodes.filter((node) => {
        if (
          checkedNodes.findIndex(
            (nd) => nd.id == node.id && nd.template_id == node.template_id
          ) > -1
        ) {
          return true;
        }
      });
      let isDataTableSelected = (checked || []).filter(
        (e) => e.type == "DATA_TABLE"
      );
      if (
        isDataTableSelected &&
        isDataTableSelected.length == 1 &&
        !this.dataTableSelected
      ) {
        this.dataTableSelected = true;
      } else if (isDataTableSelected && isDataTableSelected.length > 1) {
        this.$message({
          message: "Only one data table can select at a time. Please select only one data table.",
          type: "warning",
        });
        this.refresh = true;
        let notExistedData = checked
          .filter((e) => e.type != "DATA_TABLE")
          .flatMap((e) => e.id);
        this.$refs.tree.setCheckedKeys(notExistedData);
        setTimeout(() => {
          this.refresh = false;
        });
      }
    },
    openViewConfigPage() {
      this.entityViewModal = true;
    },

    filterNode(value, data) {
      if (!value) return true;
      return data.label.toLowerCase().indexOf(value.toLowerCase()) !== -1;
    },
    isFloat(n) {
      return Number(n) === n && n % 1 !== 0;
    },

    generateKeyForField(label) {
      let parsedLabel = label.toLowerCase().replace(/[^a-zA-Z 0-9]/g, "");
      parsedLabel = parsedLabel.toLowerCase().split(" ").join("_");

      return parsedLabel;
    },
    getLabel(field) {
      let found = this.getField(field);
      return found && found.label ? found.label : "";
    },

    WANotification({type , entityData})
    {
      console.log("Type",type)
      this.openPopup("WAConfig", {
        currentEntity: this.currentEntity,
        entityData: entityData,
        isSendIndividual: true,
        selectedIds: this.selectedIds,
        paramsContent: this.paramsContent,
        isAutomation: false,
        keyValue: this.keyValue,
        hideActions: this.hideActions,
        allFields: this.allFileFields,
        emailTemplateId: this.emailTemplateId,
        closeNotificationDialog : this.closeNotificationDialog,
        type: "WAConfig"
      })
    },   
    EmailConfig({type, entityData})
    {
      console.log("Type",type)
      this.openPopup("EmailConfig", {
        currentEntity: this.currentEntity,
        entityData: entityData,
        isSendIndividual: true,
        selectedIds: this.selectedIds,
        paramsContent: this.paramsContent,
        isAutomation: false,
        keyValue: this.keyValue,
        allFields: this.allFileFields,
        emailTemplateId: this.emailTemplateId,
        // entityName: this.entityName,
        allEntities: this.allEntitiesData,
        companyAllTemplateFields: this.companyAllTemplateFields,
        parentEmailTemplateId: this.parentEmailTemplateId,
        closeNotificationDialog : this.closeNotificationDialog,
        type: "EmailConfig"
      })
    },


    async resetStore() {
      this.$store.commit("navigationOpen/setCurrentMenu", {}, { root: true });
      this.$store.commit("entities/setEntityDataById", null, { root: true });
      this.$store.commit("entities/setEntityDataByEntityId", null, {
        root: true,
      });
      this.$store.commit("entities/setEntityDataUpdateStatus", null, {
        root: true,
      });
      this.$store.commit("templatesData/setTemplateDeleteStatus", null, {
        root: true,
      });
      this.$store.commit("templatesData/setTemplateDeleteError", null, {
        root: true,
      });
      this.$store.commit("entities/setEntityById", null, { root: true });
      this.$store.commit("entities/setEntityRecordsForTable", false, {
        root: true,
      });
      this.$store.commit("entities/setEntitiesDataErrors", {}, { root: true });
      this.$store.commit("entities/setBulkUpdateStatus", false, { root: true });
      this.$store.commit("entities/setSentEntityDataNotificationStatus", false, {
        root: true,
      });
      this.$store.commit("entities/setEntityShowFields", null, { root: true });
      this.$store.commit("entities/setuploadedFilesData", null, { root: true });
      this.$store.commit("entities/setEntityDataExportStatus", false, {
        root: true,
      });
      this.$store.commit("entities/setUploadCSVfileStatus", null, { root: true });
      this.$store.commit("entities/setupdateMappedFieldStatus", false, {
        root: true,
      });
      this.$store.commit(
        "entities/setimportMappedDataStatusUpdatedVersion",
        false, { root: true }
      );
      this.$store.commit("entities/setEntitydataBulkDeleteErrors", null, {
        root: true,
      });
      this.$store.commit("entities/setentitydataBulkDeleteStatus", null, {
        root: true,
      });
      this.$store.commit("entities/setEntityRecordsForTable", false, {
        root: true,
      });
      this.$store.commit("entities/setEntitiesDataErrors", {}, { root: true });
      this.$store.commit("entities/setEntityDataById", null, { root: true });
      this.$store.commit("entities/setEntityDataByEntityId", [], { root: true });
      this.$store.commit("templatesData/setDuplicateEntityData", null, {
        root: true,
      });
      this.$store.commit("templatesData/setDownloadUrl", null, { root: true });
      this.$store.commit("templatesData/setDownloadError", null, { root: true });
      this.$store.commit("templatesData/setTemplateDeleteStatus", false, {
        root: true,
      });
      this.$store.commit("entityRelationships/setEntityRelationships", [], {
        root: true,
      });
      this.$store.commit(
        "globalVariables/setAllGlobalVariables", { data: [], total: 0, page: 0 }, { root: true }
      );
      this.$store.commit("filters/setUpdateFilterStatus", null, { root: true });
      this.$store.commit("filters/setAllEntityFilters", null, { root: true });
      this.$store.commit("filters/setDeleteFilterStatus", false, { root: true });
      this.$store.commit("filters/setDeleteFilterErros", null, { root: true });
      this.$store.commit("filters/setCreateNewFilter", null, { root: true });
      this.$store.dispatch("entities/updatePageSizeNumber", 1);
    },
  },
  watch:{
	}
};
